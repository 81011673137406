import SimpleLightbox from 'simplelightbox'
import Swiper, { Autoplay, EffectFade } from 'swiper';
import 'swiper/css'
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

import 'lazysizes'
import 'lazysizes/plugins/respimg/ls.respimg'

Swiper.use([Autoplay, EffectFade]);

document.addEventListener("DOMContentLoaded", function() {
    const settings = {
        spaceBetween: 0,
        loop: true,
        effect: 'fade',
        autoplay: {
            delay: 3000,
            disableOnInteraction: true,
        },
    }
    
    const swiper = new Swiper('.swiper-container', settings);
});


var hamburger = document.querySelector(".hamburger");
var navigation = document.querySelector(".navigation");
hamburger.addEventListener("click", function() {
    hamburger.classList.toggle("is-active");
    navigation.classList.toggle("is-active");
});


var teamList = document.querySelector(".team-list");
var teamItems = document.querySelectorAll('.team-item');

for(var i=0; i< teamItems.length; i++) {
    teamItems[i].addEventListener("click", bindClick(i));

    teamItems[i].addEventListener("mouseover", function() {
        teamList.classList.add("hover");
    });
    teamItems[i].addEventListener("mouseout", function() {
        teamList.classList.remove("hover");
    });
}

var projectList = document.querySelector(".project-list");
var projectItems = document.querySelectorAll('.project-item');

for(var i=0; i< projectItems.length; i++) {      
    projectItems[i].addEventListener("mouseover", function() {
        projectList.classList.add("hover");
    });
    projectItems[i].addEventListener("mouseout", function() {
        projectList.classList.remove("hover");
    }); 
}

function bindClick(i) {
    return function() {
        for(var n=0; n< teamItems.length; n++) {
        if(n!=i) { teamItems[n].classList.remove("is-active"); }
        }
        teamItems[i].classList.toggle("is-active");
        if(teamItems[i].classList.contains("is-active"))
            {
            teamList.classList.add("is-active");
            teamItems[i].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        else
            {
            teamList.classList.remove("is-active");
            }
    };
}

var linkLegalNote = document.querySelector(".link-legalnote");
var linkPrivacy = document.querySelector(".link-privacy");

var navLegalNote = document.querySelector(".nav-legalnote");
var navPrivacy = document.querySelector(".nav-privacy");

if(linkLegalNote) {
    linkLegalNote.addEventListener("click", function() {
        navLegalNote.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
}

if(linkPrivacy) {
linkPrivacy.addEventListener("click", function() {
        navPrivacy.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
}


new SimpleLightbox('a.lightbox', {
    animationSlide: false,
    fadeSpeed: 200,
    showCounter: false,
    widthRatio: 1.0,
    heightRatio: 1.0,
});
